<template>
  <div>
    <ul class="tabs" uk-tab>
      <template v-for="(m, index) in modes" :key="id + '-item-' + index">
        <li class="tab" :class="[m === modelValue? 'active': '']" @click="changeModeActive(m)">{{m}}</li>
      </template>
    </ul>
    <div class="line"></div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: String
    }, 
    modes: {
      type: Array,
      required: true
    }
  },
  setup(props, {emit}) {
    const changeModeActive = function (mode) {
      emit('update:modelValue', mode)
    }
    return {
      changeModeActive
    }
    
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/color-scheme.scss";
.tabs {
  margin: 0;
  margin-bottom: 7px;
  display: flex;
  column-gap: 32px;
}

.tab {
  cursor: pointer;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  justify-self: center;
  justify-content: center;
  line-height: 22px;
  padding: 12px 0 5px 0;
  /* identical to box height, or 157% */

  color: $pxp-gray-color-darken;
  &.active {
    font-weight: 600;
    color: $pxp-green-color;
    box-shadow: 0 6px $global-secondary-background, 0 8px $pxp-green-color;
  }
}

.line {
  height: 1px;
  background-color: $pxp-gray-accent-color-dimmer;
  margin: 0;
  padding: 0;
}
</style>